<template>
	<CHeader class="main-header" with-subheader>
		<CToggler
			in-header
			class="ml-3 d-lg-none"
			@click="$store.commit('sidebar/toggleSidebarMobile')"
		/>
		<CToggler
			in-header
			class="ml-3 d-md-down-none"
			@click="$store.commit('sidebar/toggleSidebarDesktop')"
		/>
		<h3 class="header-name">
			{{ pageTitle }}
		</h3>
		<CHeaderBrand class="mx-auto d-lg-none" to="/" />
		<CHeaderNav class="profile-wrapper">
			<TheHeaderDropdownAccnt />
		</CHeaderNav>
		<CSubheader v-if="hasBreadcrumbs" class="breadcrump-wrapper px-3">
			<BaseBreadcrumb :breadcrumbs="breadcrumbs" />
		</CSubheader>
	</CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt.vue';

export default {
	name: 'TheHeader',

	components: {
		TheHeaderDropdownAccnt,
	},

	props: {
		breadcrumbs: {
			type: [Boolean, Array],
			default: false,
		},
		pageTitle: {
			type: String,
			default: '',
		},
	},

	computed: {
		hasBreadcrumbs() {
			return this.breadcrumbs.length > 0;
		},
	},
};
</script>
<style lang="scss" scoped>
	.header-name {
		@include ellipsisOneline;

		display: block;
		margin: auto 0;
		width: calc(100% - 300px);
		color: $color-white;

		> small {
			font-size: rem(12);
			opacity: 0.7;
		}
	}

	::v-deep .profile-wrapper {
		width: rem(234);

		.dropdown {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}

		.container-profile {
			span {
				@include ellipsisOneline;

				text-align: right;
				width: rem(190);
			}
		}
	}

	/*
	* Print
	*/
	@media print {
		.main-header {
			display: none;
		}
	}
</style>
