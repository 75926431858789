<template>
	<CDropdown
		in-nav
		placement="bottom-end"
		add-menu-classes="pt-0"
		class="c-header-nav-items"
	>
		<template #toggler>
			<CHeaderNavLink class="container-profile">
				<span class="mr-2">{{ enviroment }} | {{ username }}</span>
				<div class="c-avatar">
					<CIcon name="cil-user" />
				</div>
			</CHeaderNavLink>
		</template>
		<CDropdownHeader
			tag="div"
			color="light"
			class="text-center"
		>
			<strong>
				Account
			</strong>
		</CDropdownHeader>
		<CDropdownItem @click="handleEditProfile">
			<CIcon name="cil-user" class="dropdown-item-icon" />
			<span class="dropdown-item-name">
				Edit profile
			</span>
		</CDropdownItem>
		<CDropdownItem @click="handleLogout">
			<CIcon name="cil-lock-locked" class="dropdown-item-icon" />
			<span class="dropdown-item-name">
				Logout
			</span>
		</CDropdownItem>
	</CDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'TheHeaderDropdownAccnt',

	data() {
		return {
			enviroment: process.env.VUE_APP_ENVIRONMENT_TEXT,
		};
	},

	computed: {
		...mapGetters({
			username: 'auth/getUsername',
		}),
	},

	methods: {
		...mapActions({
			logout: 'auth/logout',
		}),
		async handleLogout() {
			await this.logout();
			this.$router.push({ name: 'Login' });
		},
		async handleEditProfile() {
			this.$router.push({ name: 'UserProfile' });
		},
	},
};
</script>


<style lang="scss" scoped>
	.dropdown-item-icon {
		position: relative;
		top: rem(-2);
		margin-right: rem(10);
	}

	.dropdown-item-name {
		&:hover {
			font-weight: 600;
		}
	}

	.container-profile {
		margin-right: rem(20);

		.c-avatar {
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(32);
			height: rem(32);
			border: 1px solid $color-gray-400;
		}
	}
</style>
