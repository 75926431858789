<template>
	<div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
		<TheSidebar />
		<CWrapper>
			<TheHeader
				:breadcrumbs="breadcrumbs"
				:page-title="getPageTitle"
			/>
			<div ref="c-body" class="c-body">
				<main class="c-main">
					<CContainer fluid>
						<transition name="fade" mode="out-in">
							<router-view />
						</transition>
					</CContainer>
				</main>
			</div>
		</CWrapper>
	</div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapState, mapActions } from 'vuex';
import TheSidebar from './TheSidebar.vue';
import TheHeader from './TheHeader.vue';

export default {
	name: 'TheContainer',
	components: {
		TheSidebar,
		TheHeader,
	},

	computed: {
		...mapState('pageTitle', {
			showStatePageTitle: 'isShow',
			pageTitleState: 'title',
		}),
		...mapState('breadcrumb', {
			breadcrumbs: 'items',
		}),
		getPageTitle() {
			if (this.showStatePageTitle) {
				return this.pageTitleState || this.$route.meta.pageTitle;
			}
			return this.$route.meta.pageTitle || '';
		},
	},

	watch: {
		// set breadcrumbs every times when route was changed.
		$route() {
			this.setBreadcrumbs(this.$route.meta.breadcrumbs);
		},
	},

	created() {
		// set breadcrumbs when page is refresh.
		this.setBreadcrumbs(this.$route.meta.breadcrumbs);
	},

	mounted() {
		this.handleDebouncedScroll = debounce(this.handleScroll, 50);

		this.$refs['c-body'].addEventListener('scroll', this.handleDebouncedScroll);
	},

	beforeDestroy() {
		this.$refs['c-body'].removeEventListener('scroll', this.handleDebouncedScroll);
	},

	methods: {
		...mapActions('breadcrumb', {
			setBreadcrumbs: 'setBreadcrumbs',
		}),

		handleScroll(event) {
			this.setStickyContainerCss(event.target.scrollLeft);
		},
		setStickyContainerCss(value = 0) {
			// set css variable to body element
			const el = document.querySelector('body');
			const cssVariableName = '--body-scroll-left';
			const cssValue = `${value}px`;
			el.style.setProperty(cssVariableName, cssValue);
		},
	},
};
</script>

<style lang="scss">
// declare this class as a global
// if we want to let that element sticky with body
// then add this class to the element
.sticky-container {
	position: relative;
	top: 0;
	left: var(--body-scroll-left); // set left following from body scrollbar left
}
</style>
