<template>
	<CSidebar
		:show="show"
		:minimize="minimize"
		unfoldable
		dropdown-mode="closeInactive"
	>
		<CSidebarBrand to="/" class="d-md-down-none">
			<Logo type="full" />
			<Logo />
		</CSidebarBrand>
		<!-- CRenderFunction always rerender when route is change -->
		<CRenderFunction
			flat
			:content-to-render="navigations"
		/>
	</CSidebar>
</template>

<script>
import { mapState } from 'vuex';
import Logo from '@/components/TheLogo.vue';
import nav from './_nav';

export default {
	name: 'TheSidebar',

	components: {
		Logo,
	},

	data() {
		return {
			navigations: [],
		};
	},
	computed: {
		...mapState('profile', {
			permissions: 'permissions',
			profile: 'profile',
		}),
		show() {
			return this.$store.state.sidebar.sidebarShow;
		},
		minimize() {
			return this.$store.state.sidebar.sidebarMinimize;
		},
		isProfileLoading() {
			return this.profile.isLoading;
		},
	},
	watch: {
		isProfileLoading: {
			immediate: true,
			handler(value) {
				if (!value) {
					const menu = [];
					const children = nav[0]._children;
					const userPermission = this.permissions || [];

					children.forEach((navItem) => {
						if (navItem.items) {
							const items = navItem.items.filter((item) => {
								const key = item.routePermissionKey;
								const routePermissions = userPermission.filter((permissionItem) => {
									if (Array.isArray(key)) {
										return key.includes(permissionItem.key);
									}
									return permissionItem.key === key;
								});
								return routePermissions.find((routePermission) => routePermission.permissions.view);
							});


							if (items.length) {
								menu.push({ ...navItem, items });
							}
						} else {
							const routePermissions = userPermission.find((permissionItem) => permissionItem.key === navItem.routePermissionKey);

							if (routePermissions && routePermissions.permissions.view) {
								menu.push(navItem);
							}
						}
					});

					this.navigations = [{
						_name: 'CSidebarNav',
						_children: menu,
					}];
				}
			},
		},
	},
};
</script>

