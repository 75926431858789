export const SIDEBAR_NAME = {
	SALE_ORDERS: 'Sale orders',
	ORDERS: 'Orders',
	SHIPMENTS: 'Shipments',
	MARKETINGS: 'Marketings',
	CAMPAIGN_MANAGEMENT: 'Campaign management',
	PROMOTIONS: 'Promotions',
	FLASH_SALE: 'Flash sale',
	PRE_ORDERS: 'Pre-orders',
	PRODUCT_FEED_SETTING: 'Product feed setting',
	BUNDLE_SET: 'Bundle setting',
	CUSTOMERS: 'Customers',
	CUSTOMER_MANAGEMENT: 'Customer management',
	CUSTOMER_GROUPS: 'Customer groups',
	ABANDONED_CART: 'Abandoned cart',
	CATALOGS: 'Catalogs',
	PRODUCTS: 'Products',
	CATEGORIES: 'Categories',
	PRODUCT_LABELS: 'Product Labels',
	ATTRIBUTE_SETS: 'Attribute sets',
	ATTRIBUTE_KEYS: 'Attribute keys',
	PRODUCT_FAMILIES: 'Product families',
	WAREHOUSES: 'Warehouses',
	PAGE_SETTING: 'Page setting',
	HOMEPAGE: 'Homepage',
	STATIC_PAGE: 'Static page',
	SUB_HEADERS: 'Sub headers',
	PAYMENT_SETTING: 'Payment setting',
	SHIPPING_SETTING: 'Shipping setting',
	SEARCH_SETTING: 'Search setting',
	SEARCH_SCORE: 'Search score',
	SEARCH_SYNONYMS: 'Search synonyms',
	CACHE: 'Cache',
	SEARCH_RESULT_CACHE: 'Search result cache',
	USERS: 'Users',
	USER_MANAGEMENT: 'User Management',
	ROLE_PERMISSION: 'Role & permission',
	HISTORY: 'History',
	AUDIT_LOG: 'Audit log',
	IMPORT_HISTORY: 'Import history',
	BACK_IN_STOCK_NOTIFICATIONS: 'Back-in-stock notifications',
	LIMIT_PRODUCT_PER_ACCOUNT: 'Limit Product',
	MOBILE_NOTIFICATION: 'Mobile Notification',
	TAG_FILTERS: 'Tag Filters',
	PRODUCT_DETAIL_ACCORDION_SET: 'Accordion sets',
	PRODUCT_DETAIL_ACCORDION_KEY: 'Accordion keys',
	PROMOTION_BANNER_SET: 'Product detail promotion banner sets',
	PROMOTION_BANNER_KEY: 'Product detail promotion banner keys',
	FOOTERS: 'Footers',
	VARIANT_INFO: 'Variant info',
	PRODUCT_COMPARE: 'Product compare',
	ORDER_AUTO_COMPLETE: 'Orders auto-complete',
	DATE_TIME_CACHE: 'Date time cache',
};
