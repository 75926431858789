import { ROUTE_PERMISSION_KEY } from '../enums/permissions';
import { SIDEBAR_NAME } from '../enums/sidebar';

export default [{
	_name: 'CSidebarNav',
	_children: [
		{
			_name: 'CSidebarNavDropdown',
			name: SIDEBAR_NAME.SALE_ORDERS,
			route: '/sale',
			icon: 'cil-clipboard',
			items: [
				{
					name: SIDEBAR_NAME.ORDERS,
					to: '/sale/orders',
					routePermissionKey: ROUTE_PERMISSION_KEY.ORDER,
				},
				{
					name: SIDEBAR_NAME.SHIPMENTS,
					to: '/sale/shipments',
					routePermissionKey: [ROUTE_PERMISSION_KEY.SHIPMENT, ROUTE_PERMISSION_KEY.BRANCH_SHIPMENTS],
				},
				{
					name: SIDEBAR_NAME.ORDER_AUTO_COMPLETE,
					to: '/sale/order-auto-complete',
					routePermissionKey: ROUTE_PERMISSION_KEY.ORDER_AUTO_COMPLETE,
				},
			],
		},
		{
			_name: 'CSidebarNavDropdown',
			name: SIDEBAR_NAME.MARKETINGS,
			route: '#',
			icon: 'cil-calendar',
			items: [
				{
					name: SIDEBAR_NAME.CAMPAIGN_MANAGEMENT,
					to: '/marketing-campaigns',
					routePermissionKey: ROUTE_PERMISSION_KEY.CAMPAIGN_MANAGEMENT,
				},
				{
					name: SIDEBAR_NAME.PROMOTIONS,
					to: '/promotions',
					routePermissionKey: ROUTE_PERMISSION_KEY.PROMOTION,
				},
				{
					name: SIDEBAR_NAME.FLASH_SALE,
					to: '/flashsales',
					routePermissionKey: ROUTE_PERMISSION_KEY.FLASH_SALE,
				},
				{
					name: SIDEBAR_NAME.PRE_ORDERS,
					to: '/preorders',
					routePermissionKey: ROUTE_PERMISSION_KEY.PRE_ORDER,
				},
				{
					name: SIDEBAR_NAME.PRODUCT_FEED_SETTING,
					to: '/feeds',
					routePermissionKey: ROUTE_PERMISSION_KEY.FEED,
				},
				{
					name: SIDEBAR_NAME.BUNDLE_SET,
					to: '/bundle-settings',
					routePermissionKey: ROUTE_PERMISSION_KEY.BUNDLE_SET,
				},
				{
					name: SIDEBAR_NAME.MOBILE_NOTIFICATION,
					to: '/mobile-notification',
					routePermissionKey: ROUTE_PERMISSION_KEY.MOBILE_NOTIFICATION,
				},
				{
					name: SIDEBAR_NAME.BACK_IN_STOCK_NOTIFICATIONS,
					to: '/back-in-stock-notification',
					routePermissionKey: ROUTE_PERMISSION_KEY.BACK_IN_STOCK_NOTIFICATIONS,
				},
				{
					name: SIDEBAR_NAME.LIMIT_PRODUCT_PER_ACCOUNT,
					to: '/limit-product',
					routePermissionKey: ROUTE_PERMISSION_KEY.LIMIT_PRODUCT_PER_ACCOUNT,
				},
				{
					name: SIDEBAR_NAME.TAG_FILTERS,
					to: '/tag-filters',
					routePermissionKey: ROUTE_PERMISSION_KEY.TAG_FILTER,
				},
			],
		},
		{
			_name: 'CSidebarNavDropdown',
			name: SIDEBAR_NAME.CUSTOMERS,
			route: '#',
			icon: 'cil-people',
			items: [
				{
					name: SIDEBAR_NAME.CUSTOMER_MANAGEMENT,
					to: '/customers',
					routePermissionKey: ROUTE_PERMISSION_KEY.CUSTOMER,
				},
				{
					name: SIDEBAR_NAME.CUSTOMER_GROUPS,
					to: '/customer-groups',
					routePermissionKey: ROUTE_PERMISSION_KEY.CUSTOMER_GROUP,
				},
				{
					name: SIDEBAR_NAME.ABANDONED_CART,
					to: '/abandoned-carts',
					routePermissionKey: ROUTE_PERMISSION_KEY.ABANDONED_CART,
				},
			],
		},
		{
			_name: 'CSidebarNavDropdown',
			name: SIDEBAR_NAME.CATALOGS,
			route: '/#',
			icon: 'cil-map',
			items: [
				{
					name: SIDEBAR_NAME.PRODUCTS,
					to: '/products',
					routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT,
				},
				{
					name: SIDEBAR_NAME.CATEGORIES,
					to: '/categories',
					routePermissionKey: ROUTE_PERMISSION_KEY.CATEGORY,
				},
				{
					name: SIDEBAR_NAME.PRODUCT_LABELS,
					to: '/labels',
					routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT_LABEL,
				},
				{
					name: SIDEBAR_NAME.ATTRIBUTE_SETS,
					to: '/attribute-sets',
					routePermissionKey: ROUTE_PERMISSION_KEY.ATTRIBUTE_SET,
				},
				{
					name: SIDEBAR_NAME.ATTRIBUTE_KEYS,
					to: '/attribute-keys',
					routePermissionKey: ROUTE_PERMISSION_KEY.ATTRIBUTE_KEY,
				},
				{
					name: SIDEBAR_NAME.PRODUCT_FAMILIES,
					to: '/product-families',
					routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT_FAMILIES,
				},
				{
					name: SIDEBAR_NAME.PRODUCT_DETAIL_ACCORDION_KEY,
					to: '/pdp-accordion-key',
					routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT_DETAIL_ACCORDION_KEY,
				},
				{
					name: SIDEBAR_NAME.PRODUCT_DETAIL_ACCORDION_SET,
					to: '/pdp-accordion-set',
					routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT_DETAIL_ACCORDION_SET,
				},
				{
					name: SIDEBAR_NAME.PROMOTION_BANNER_SET,
					to: '/promotion-banner-set',
					routePermissionKey: ROUTE_PERMISSION_KEY.PROMOTION_BANNER_SET,
				},
				{
					name: SIDEBAR_NAME.PROMOTION_BANNER_KEY,
					to: '/promotion-banner-key',
					routePermissionKey: ROUTE_PERMISSION_KEY.PROMOTION_BANNER_KEY,
				},
				{
					name: SIDEBAR_NAME.VARIANT_INFO,
					to: '/variant-info',
					routePermissionKey: ROUTE_PERMISSION_KEY.VARIANT_INFO,
				},
				{
					name: SIDEBAR_NAME.PRODUCT_COMPARE,
					to: '/product-compare',
					routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT,
				},
			],
		},
		{
			_name: 'CSidebarNavItem',
			name: SIDEBAR_NAME.WAREHOUSES,
			to: '/warehouses',
			icon: 'cil-house',
			routePermissionKey: ROUTE_PERMISSION_KEY.WAREHOUSE,
		},
		{
			_name: 'CSidebarNavDropdown',
			name: SIDEBAR_NAME.PAGE_SETTING,
			route: '/pages',
			icon: 'cil-view-quilt',
			items: [
				{
					name: SIDEBAR_NAME.HOMEPAGE,
					to: '/homepage',
					routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
				},
				{
					name: SIDEBAR_NAME.STATIC_PAGE,
					to: '/pages',
					routePermissionKey: ROUTE_PERMISSION_KEY.STATIC_PAGE,
				},
				{
					name: SIDEBAR_NAME.SUB_HEADERS,
					to: '/sub-headers',
					routePermissionKey: ROUTE_PERMISSION_KEY.SUB_HEADERS,
				},
				{
					name: SIDEBAR_NAME.FOOTERS,
					to: '/footers',
					routePermissionKey: ROUTE_PERMISSION_KEY.FOOTER,
				},
				// TODO: Bring it back after Production, Change route permission key for microsite
				/*
				{
					name: SIDEBAR_NAME.Microsite Apple',
					to: '/microsite',
					routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
				},
				*/
			],
		},
		{
			_name: 'CSidebarNavItem',
			name: SIDEBAR_NAME.PAYMENT_SETTING,
			to: '/payments',
			icon: 'cil-money',
			routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
		},
		{
			_name: 'CSidebarNavItem',
			name: SIDEBAR_NAME.SHIPPING_SETTING,
			to: '/shipping-setting',
			icon: 'cil-truck',
			routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
		},
		{
			_name: 'CSidebarNavDropdown',
			name: SIDEBAR_NAME.SEARCH_SETTING,
			route: '/search',
			icon: 'cil-magnifying-glass',
			items: [
				{
					name: SIDEBAR_NAME.SEARCH_SCORE,
					to: '/search/search-weights',
					routePermissionKey: ROUTE_PERMISSION_KEY.SEARCH_WEIGHT,
				},
				{
					name: SIDEBAR_NAME.SEARCH_SYNONYMS,
					to: '/search/search-synonyms',
					routePermissionKey: ROUTE_PERMISSION_KEY.SEARCH_SYNONYMS,
				},
			],
		},
		{
			_name: 'CSidebarNavDropdown',
			name: SIDEBAR_NAME.USERS,
			route: '/users',
			icon: 'cil-user',
			items: [
				{
					name: SIDEBAR_NAME.USER_MANAGEMENT,
					to: '/users',
					routePermissionKey: ROUTE_PERMISSION_KEY.USER,
				},
				{
					name: SIDEBAR_NAME.ROLE_PERMISSION,
					to: '/users/roles',
					routePermissionKey: ROUTE_PERMISSION_KEY.ROLE_PERMISSION,
				},
			],
		},
		{
			_name: 'CSidebarNavDropdown',
			name: SIDEBAR_NAME.HISTORY,
			route: '#',
			icon: 'cil-data-transfer-down',
			items: [
				{
					name: SIDEBAR_NAME.AUDIT_LOG,
					to: '/audit-logs',
					routePermissionKey: ROUTE_PERMISSION_KEY.AUDIT_LOG,
				},
				{
					name: SIDEBAR_NAME.IMPORT_HISTORY,
					to: '/products/import/history',
					routePermissionKey: ROUTE_PERMISSION_KEY.IMPORT_HISTORY,
				},
			],
		},
		{
			_name: 'CSidebarNavDropdown',
			name: SIDEBAR_NAME.CACHE,
			route: '/cache',
			icon: 'cil-storage',
			items: [
				{
					name: SIDEBAR_NAME.SEARCH_RESULT_CACHE,
					to: '/cache/clear-search-result-cache',
					routePermissionKey: ROUTE_PERMISSION_KEY.SEARCH_RESULT_CACHE,
				},
				{
					name: SIDEBAR_NAME.DATE_TIME_CACHE,
					to: '/cache/clear-date-time-cache',
					routePermissionKey: ROUTE_PERMISSION_KEY.DATE_TIME_CACHE,
				},
			],
		},
	],
}];
